import * as React from 'react'
import { useCallback, useState } from 'react'
import MapGL, {
  Layer,
  NavigationControl,
  ScaleControl,
  Source,
} from 'react-map-gl'
import GlobalStateContext from '../context/globalStateContext'
import GeocoderControl from './geocoder-control'

const layerStyle = {
  id: 'newa-stations',
  type: 'symbol',
  source: 'composite',
  layout: {
    'icon-image': [
      'step',
      ['zoom'],
      ['case', ['==', ['get', 'isIcao'], true], 'airport', 'newa'],
      22,
      ['case', ['==', ['get', 'isIcao'], true], 'airport', 'newa'],
    ],
    'text-field': ['step', ['zoom'], '', 8, ['to-string', ['get', 'name']]],
    'text-size': 11,
    'text-anchor': 'bottom',
    'text-offset': [0, -0.7],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-size': ['case', ['==', ['get', 'isIcao'], false], 1.4, 1],
  },
}

export default function Map({ station, setStation, geoJSON }) {
  const mapRef = React.useRef()
  const [cursor, setCursor] = useState('auto')

  const { DEFAULT_STATION } = React.useContext(GlobalStateContext)

  const onClick = useCallback(
    (event) => {
      const feature = event.features && event.features[0]
      if (feature && feature.source === 'newa-stations') {
        const json = JSON.stringify(feature.properties)
        const stn = JSON.parse(json)
        if (stn.elems.length !== 0) {
          stn.elems = JSON.parse(stn.elems)
        }
        if (stn.extraelems.length !== 0) {
          stn.extraelems = JSON.parse(stn.extraelems)
        }
        setStation(stn)
      }
    },
    [setStation],
  )

  const onMouseEnter = useCallback(() => setCursor('pointer'), [])
  const onMouseLeave = useCallback(() => setCursor('auto'), [])

  React.useEffect(() => {
    if (station === null) return
    mapRef.current?.flyTo({
      center: [station.lon, station.lat],
      duration: 2000,
      zoom: 9,
    })
  }, [station])

  return (
    <div className="relative h-96 lg:h-128">
      <MapGL
        id="newaStationsMap"
        ref={mapRef}
        initialViewState={{
          longitude: station ? station.lon : DEFAULT_STATION.lon,
          latitude: station ? station.lat : DEFAULT_STATION.lat,
          zoom: 9,
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle={'mapbox://styles/nysipm/cl0gkib5s00g715p2nv3rw5z3'}
        mapboxAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
        clickTolerance={3}
        dragRotate={false}
        interactiveLayerIds={[layerStyle.id]}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        cursor={cursor}
      >
        <Source id="newa-stations" type="geojson" data={geoJSON}>
          <Layer {...layerStyle}></Layer>
        </Source>

        <GeocoderControl
          // id="geocoderDestination"
          // aria-labelledby="geocoderDestination geocoderSource"
          minLength={4}
          countries="US"
          mapboxAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
          position="top-left"
        />

        {/* Items on Map */}
        <NavigationControl
          position="top-right"
          showCompass={false}
        ></NavigationControl>

        <ScaleControl unit="imperial" position="bottom-right" />
      </MapGL>
    </div>
  )
}
