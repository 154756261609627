// import useFetchRadar from '../utils/hooks/useFetchRadar'
import Card from "./card";
import React from "react";
import useStations from "../utils/hooks/useStations";
import { Link } from "gatsby";

export default function CropManagementTool() {
  const { station } = useStations();
  // const { data: radarUrl } = useFetchRadar(station)
  // console.log(radarUrl)
  return (
    <div>
      <Card
        title="Weather Tools"
        btnLabel="All Tools"
        to="/weather-tools/"
        color="primary"
      >
        <div className="flex flex-wrap py-5 bg-white md:px-3">
          {[
            {
              name: "About Weather Stations",
              to: "/about-weather-stations",
              isActive: true,
            },
            {
              name: "DD Calculator",
              to: "/degree-day-calculator",
              isActive: true,
            },
            {
              name: "Buy a Weather Station",
              to: "/buy-a-weather-station",
              isActive: true,
            },
            {
              name: "Weather Data Query",
              to: "/all-weather-data-query",
              isActive: true,
            },
          ].map((d, i) => (
            <Link
              key={i}
              to={d.to}
              className={`w-full sm:w-1/2 md:w-1/3 h-8 my-1 flex justify-center items-center font-bold ${
                i !== 2 && i !== 5 && "border-r"
              }`}
            >
              {d.name}
            </Link>
          ))}

          <a
            href={`${station ? `https://www.weather.gov/` : ``}`}
            className={`w-full sm:w-1/2 md:w-1/3 h-8 my-1 flex justify-center items-center  font-bold border-r`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Regional Radar
          </a>
          <a
            href={"https://droughtmonitor.unl.edu/"}
            className={`w-full sm:w-1/2 md:w-1/3 h-8 my-1 flex justify-center items-center  font-bold border-r`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Drought Monitor
          </a>
        </div>
      </Card>
    </div>
  );
}
