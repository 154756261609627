import { useQuery } from 'react-query'
import { fetchStationData } from '../fetchData'

export default function useFetchStationData(
  station = null,
  startDate = null,
  endDate = null,
) {
  let sid = ''
  if (station !== null) sid = station.id

  const currentYear = new Date().getFullYear()
  let sdate = `${currentYear}010100`
  if (startDate) sdate = startDate

  let edate = 'now'
  if (endDate) edate = endDate

  const body = {
    sid,
    sdate,
    edate,
    extraelems: station ? station.extraelems : '',
  }

  // let stationName = ''
  // if (station !== null) stationName = station.name

  // const year = Number(sdate.slice(0, 4))
  const { data, isLoading, isError } = useQuery(
    `${body.sdate}-${body.edate} (${sid})`,
    () => fetchStationData(body),
    {
      refetchOnWindowFocus: false,
      enabled: station !== null,
      refetchOnMount: false,
      cacheTime: 900000, // data remains in memory for 15 minutes
    },
  )

  return { data, isLoading, isError }
}
