import { Link } from 'gatsby'
import React from 'react'
import Card from './card'

export default function IpmAndCropTools() {
  return (
    <div>
      <Card
        title="IPM & Crop Tools"
        btnLabel="All Tools"
        to="/crop-and-pest-management/"
        color="primary"
      >
        <div className="flex flex-wrap items-center justify-center py-3 bg-white md:py-5">
          {[
            {
              name: 'Pollen Tube Growth Model',
              to: '/pollen-tube-growth-model',
            },
            {
              name: 'Carbohydrate Thinning',
              to: '/apple-carbohydrate-thinning',
            },
            {
              name: 'Grape Berry Moth',
              to: '/grape-berry-moth',
            },
            {
              name: 'Grape Diseases',
              to: '/grape-diseases',
            },
            {
              name: 'Cabbage Maggot',
              to: '/cabbage-maggot',
            },
            {
              name: 'Beet Cercospora Leaf Spot',
              to: '/beet-cercospora-leaf-spot',
            },
          ].map((d, i) => (
            <div
              key={i}
              className={`w-full sm:w-1/2 md:w-1/3 h-8 my-1 flex justify-center items-center font-semibold ${
                i !== 2 && i !== 5 && 'border-r'
              }`}
            >
              <Link to={d.to} className="text-center underline">
                {d.name}
              </Link>
            </div>
          ))}
        </div>
      </Card>
    </div>
  )
}
